import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyD3jzs4mBXTFJ6LBjVxLvktdWpknvzh7ZU",
  authDomain: "old-story-52752.firebaseapp.com",
  projectId: "old-story-52752",
  storageBucket: "old-story-52752.appspot.com",
  messagingSenderId: "937143333136",
  appId: "1:937143333136:web:f5312aee67437b09b8b595",
  measurementId: "G-KFW7KL3LCR"
};
/*
old app for old stroy
const firebaseConfig = {
  apiKey: "AIzaSyAU8Iz43JRVOBv3nLwhRc5P4zvZQ6R51DU",
  authDomain: "old-story-7cd1f.firebaseapp.com",
  projectId: "old-story-7cd1f",
  storageBucket: "old-story-7cd1f.appspot.com",
  messagingSenderId: "867008349552",
  appId: "1:867008349552:web:0cea4c2e111f3684007bb5",
  measurementId: "G-10WB45JS19"
};

by wr team

const firebaseConfig = {
  apiKey: "AIzaSyC6-DZ3rX5H7RqzOdlq4alYsRKsUkU_F6I",
  authDomain: "news-9b899.firebaseapp.com",
  projectId: "news-9b899",
  storageBucket: "news-9b899.appspot.com",
  messagingSenderId: "1085975883181",
  appId: "1:1085975883181:web:7084701e5bd77855704524",
  measurementId: "G-5WV6VE3XFK"
};
*/
const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
